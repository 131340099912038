import axios from 'axios';

export const fetchTitleDetails = async (
    trackTitle,
    actorTitle,
    classTrait,
    styleTrait,
    watchVideo) => {
    try {
        const response = await axios.get(
            '/api/titleDetails', {
            params: {
                trackTitle,
                actorTitle,
                classTrait,
                styleTrait,
                watchVideo
            },
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};
