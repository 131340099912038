import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import ContentVideo from "../components/ContentVideo";
import {Container} from "@mui/material";
import TitleSuggest from "../components/TitleSuggest";
import {fetchActorDetails} from "../api/ActorDetails";
import Grid from "@mui/material/Unstable_Grid2";
import withWidth from "@mui/material/Hidden/withWidth";

const ActorDetails = ({width}) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [queryParam] =
        useSearchParams();

    const getActor = (location) => {
        let actorKey = location.state && location.state.hasOwnProperty(
            'actorKey') && location.state.videoActor ? location.state.actorKey : queryParam.get('a');

        return { actorKey: actorKey };
    }

    const [actor, setActor] = useState(getActor(location));

    if (actor.actorKey !== getActor(location).actorKey) {
        setActor(getActor(location));
    }

    const [videoBlock, setVideoBlock] = useState([]);

    useEffect(() => {
        const updatePage = async (
                actorKey) => {
            try {
                const response = await fetchActorDetails(
                    actorKey
                );

                setActor(
                    response.actorSummary
                        [0]);

                setVideoBlock(
                    response.actorContent);
            } catch (error) {
                console.error(
                    "Error fetching title content: ", error);
            }
        };

        updatePage(
            actor.actorKey).then();
    }, [
        actor]);

    const onInputSelected = (
        titleVideo) => {
        navigateToInput(
            titleVideo,
            0);
    };

    const onVideoSelected = (video) => {
        navigateToVideo(video, 0);
    };

    const navigateToInput = (
        video, replace) => {
        let trackTitle = '';
        let actorTitle = '';
        let classTrait = '';
        let styleTrait = '';
        let watchVideo = video;

        navigate(`/td?v=${watchVideo}`,
            {
                state: {
                    trackTitle,
                    actorTitle,
                    classTrait,
                    styleTrait,
                    watchVideo,
                },
                replace: replace
            });
    };

    const navigateToVideo = (
        video, replace) => {
        let trackTitle = '';
        let actorTitle = '';
        let classTrait = '';
        let styleTrait = '';
        let watchVideo = video.videoKey;

        navigate(`/td?v=${
            watchVideo}`,
            {
                state: {
                    trackTitle,
                    actorTitle,
                    classTrait,
                    styleTrait,
                    watchVideo
                },
                replace: replace
            });
    };

    const isSmallScreen = /xs/.test(width);

    return (
        <React.Fragment>
            <Container maxWidth='lg' disableGutters={isSmallScreen}>
                <TitleSuggest onInputSelected={onInputSelected}/>

                <Grid container xs={12}>
                    {videoBlock.map((video, index) => (
                        <Grid key={index} xs={12} sm={6} md={4} sx={{ px: {xs: 0, sm: 2}, py: {xs: 2} }}>
                            <ContentVideo
                                video={video}
                                onVideoSelected={onVideoSelected}
                            />
                        </Grid>
                    ))}
                </Grid>

            </Container>
        </React.Fragment>
    );
};

export default withWidth()(ActorDetails);
