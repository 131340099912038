import React, {useEffect, useState} from 'react';
import {AppBar, Autocomplete, InputAdornment, TextField, Toolbar} from '@mui/material';
import {fetchTitleSuggest} from "../api/TitleSuggest";
import SearchIcon from '@mui/icons-material/Search';

const TitleSuggest = ({ onInputSelected }) => {
    const [titleQuery,       setTitleQuery]       = useState('');
    const [suggestTitleList, setSuggestTitleList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        const fetchData = async () => {
            try {
                const response = await fetchTitleSuggest(
                    titleQuery
                );

                if (response.suggestTitle) {
                    setSuggestTitleList(response.suggestTitle);
                }
                setIsLoading(false);
            } catch (error) {
                console.error(
                    "Error fetching title suggest: ", error);
                setIsLoading(false);
            }
        };
        fetchData();
    }, [titleQuery]);

    const getOptionLabel = (option) => {
        return  option.trackTitle + ' - ' + option.actorTitle;
    }

    const onInputChange = (event, value) => {
        setTitleQuery(value);
    }

    const onChange = (event, value) => {
        if (value && value.titleVideo) {
            onInputSelected(value.titleVideo);
        }
    };

    return (
        <AppBar position="sticky" elevation={0} style={{ background: '#FFFFFF' }}>
            <Toolbar sx={{px: {xs: 1, md: 0} }}>
                <Autocomplete
                    clearOnBlur={false}
                    disablePortal
                    filterOptions={(x) => x}
                    loading={isLoading}
                    options={suggestTitleList}
                    getOptionLabel={getOptionLabel}
                    onChange={onChange}
                    onInputChange={onInputChange}
                    sx={{
                        width: { xs: '100%', md: '75ch' }, marginLeft: 'auto', marginRight: 'auto'
                    }}
                    ListboxProps={{
                        sx: { fontWeight: 600 },
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label = {''}
                            size  = {'small'}
                            InputProps={{
                                ...params.InputProps,
                                style: { fontWeight: 400 },
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                />
            </Toolbar>
        </AppBar>
    );
};

export default TitleSuggest;
