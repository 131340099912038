import axios from 'axios';

export const fetchActorDetails = async (
    actorKey) => {
    try {
        const response = await axios.get(
            '/api/actorDetails', {
            params: {
                actorKey
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};
