import axios from 'axios';

export const fetchTitleExplore = async (
    batchIndex,
    styleTrait) => {
    try {
        const response = await axios.get(
            '/api/titleExplore', {
            params: {
                batchIndex,
                styleTrait
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};
