import axios from 'axios';

export const fetchTitleSuggest = async (
    titleQuery) => {
    try {
        const response = await axios.get(
            '/api/titleSuggest', {
            params: {
                titleQuery
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};
