import React, {useEffect, useState} from 'react';
import {fetchTitleExplore} from '../api/TitleExplore';
import TitleSuggest from "../components/TitleSuggest";
import ExploreVideo from "../components/ExploreVideo";
import {useNavigate} from 'react-router-dom';
import {Container} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import withWidth from "@mui/material/Hidden/withWidth";

const TitleExplore = ({width}) => {
    const navigate = useNavigate();

    const [batchIndex] = useState(
        Math.floor(Math.random() * 256));

    const [classTrait] = useState('');
    const [styleTrait] = useState('');

    const [titleBlock, setTitleBlock] = useState([]);

    useEffect(() => {
        const updatePage = async () => {
            try {
                const response = await fetchTitleExplore(
                    batchIndex,
                    styleTrait
                );

                setTitleBlock(response.titleContent);
            } catch (error) {
                console.error(
                    "Error fetching title explore: ", error);
            }
        };

        updatePage().then();
    }, [batchIndex, styleTrait]);

    const onInputSelected = (
        titleVideo) => {
        navigateToInput(
            titleVideo,
            0);
    };

    const onVideoSelected = (video) => {
        navigateToVideo(video, 0);
    };

    const navigateToInput = (
        video, replace) => {
        let trackTitle = '';
        let actorTitle = ''
        let classTrait = '';
        let styleTrait = '';
        let watchVideo = video;

        navigate(`/td?v=${watchVideo}`,
            {
                state: {
                    trackTitle,
                    actorTitle,
                    classTrait,
                    styleTrait,
                    watchVideo,
                },
                replace: replace
            });
    };

    const navigateToVideo = (
        video, replace) => {
        let trackTitle = video.trackTitle;
        let actorTitle = video.actorTitle;
        let watchVideo = video.videoKey;

        navigate(`/td?v=${watchVideo}`,
            {
                state: {
                    trackTitle,
                    actorTitle,
                    classTrait,
                    styleTrait,
                    watchVideo
                },
                replace: replace
            });
    };

    const isSmallScreen = /xs/.test(width);

    return (
        <React.Fragment>
            <Container maxWidth='lg' disableGutters={isSmallScreen}>
                <TitleSuggest onInputSelected={onInputSelected}/>

                <Grid container>
                    {titleBlock.map((video, index) => (
                        <Grid key={index} xs={12} sm={6} md={4} lg={3} sx={{ px: {xs: 0, sm: 2}, py: {xs: 2} }}>
                            <ExploreVideo
                                video={video}
                                onVideoSelected={onVideoSelected}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Container>

        </React.Fragment>
    );
};

export default withWidth()(TitleExplore);
