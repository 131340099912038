import React from 'react';
import {Avatar, CardMedia, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const ProgramVideo = ({ video, modus, onVideoSelected, onOwnerSelected }) => {
    return (
        <Grid container
              style={{ cursor: 'pointer' }}
              onClick={() => onVideoSelected(video)}>
            <Grid xs={12} sm='auto'>
                <CardMedia
                    component='img'
                    image={video.videoThumb[1] ? video.videoThumb[1] : video.videoThumb[0]}
                    alt  ={`${video.videoTitle} thumbnail`}
                    title={`${video.videoTitle} thumbnail`}
                    sx={{ width: {sm: 240}, height: {sm: 135}, borderRadius: {xs: '0px', sm: '12px'} }}
                />
            </Grid>

            <Grid container xs={12}>
                <Grid xs='auto' sx={{px: 1, py: 1}}
                      display='flex' alignItems='center'>
                    <Avatar
                        src={video.ownerThumb[0]}
                        alt  ={video.ownerTitle}
                        title={video.ownerTitle}
                        sx={{ width: 36, height: 36 }}
                        onClick={(e) => {e.stopPropagation(); onOwnerSelected(video)}}
                    />
                </Grid>

                <Grid xs sx={{py: 1}}
                      display='flex' alignItems='center'>
                    <Typography sx={{ fontWeight: 600, color: '#000000' }}>
                        {video.trackTitle + ' - ' + (modus === 1 ? video.ownerTitle : video.actorTitle)}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ProgramVideo;
