import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import TitleExplore from './pages/TitleExplore';
import TitleDetails from './pages/TitleDetails';
import ActorSummary from './pages/ActorDetails';

function App() {
  return (
    <Router>
        <Routes>
            <Route path="/"   element={<TitleExplore/>} />
            <Route path="/tx" element={<TitleExplore/>} />
            <Route path="/td" element={<TitleDetails/>} />
            <Route path="/ad" element={<ActorSummary/>} />
        </Routes>
    </Router>
  );
}

export default App;
